@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato"),
    url("./assets/fonts/lato-v23-latin-regular.woff2") format("woff2"),
    url("./assets/fonts/lato-v23-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: local("Lato"),
    url("./assets/fonts/lato-v23-latin-italic.woff2") format("woff2"),
    url("./assets/fonts/lato-v23-latin-italic.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato"),
    url("./assets/fonts/lato-v23-latin-700.woff2") format("woff2"),
    url("./assets/fonts/lato-v23-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato"),
    url("./assets/fonts/lato-v23-latin-900.woff2") format("woff2"),
    url("./assets/fonts/lato-v23-latin-900.woff") format("woff");
}

html {
  height: 100%;
  /*
    Disables pull-to-refresh and overscroll glow effects.
    https://developer.chrome.com/blog/overscroll-behavior/
  */
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chakra Overrides */

.chakra-modal__content-container .chakra-modal__content {
  border-radius: 20px;
}

.chakra-select__wrapper .chakra-select {
  height: 36px;
  border-radius: 18px;
  line-height: 16px;
  padding-left: 20px;
}

.chakra-modal__close-btn svg {
  fill: #9193a8;
  width: 9px;
  height: 9px;
}
