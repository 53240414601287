.PhoneInputInput {
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 7px;
}

svg.disabled > g > g {
  filter: none;
}

svg.disabled {
  opacity: 0.3;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
